<template>
  <div class="container-main-content resetPassword">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-3">Đặt lại mật khẩu</h4>
        <div class="content-inner__body-form">
          <ValidationObserver ref="formPassword">
            <form @keyup.enter="handleChangePassword">
              <ValidationProvider
                name="Mật khẩu mới"
                rules="required|min3"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="password"
                    v-model.trim="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="name@example.com"
                  />
                  <label for="floatingPassword">Mật khẩu mới</label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="Xác nhận mật khẩu mới"
                rules="required|password:@Mật khẩu mới"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-4">
                  <input
                    type="password"
                    v-model.trim="confirmPassword"
                    class="form-control"
                    id="floatingConfirmPassword"
                    placeholder="name@example.com"
                  />
                  <label for="floatingConfirmPassword">
                    Xác nhận mật khẩu mới
                  </label>
                  <p class="text-danger f12 mt-1">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
          <button
            type="button"
            class="btn btn-primary mb-4"
            @click="handleChangePassword"
          >
            ĐẶT LẠI MẬT KHẨU
          </button>
        </div>
      </div>
      <Footer />
    </div>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      token: this.$route.query.token,
      password: "",
      confirmPassword: "",
      loading: {
        isLoading: false,
        fullPage: true,
      },
    };
  },
  methods: {
    handleChangePassword() {
      this.$refs.formPassword.validate().then(async (success) => {
        if (success) {
          try {
            this.loading.isLoading = true;
            const response = await UserService.changePasswordByUrl(
              this.token,
              this.password
            );
            if (response.code === "SUCCESS") {
              const callback = this.$route.query.callbackUri;
              let routerObj = { path: "reset-password-success", query: {} };
              if (callback) {
                console.log("has callback");
                routerObj.query = { callbackUri: callback };
              }
              this.$router.push(routerObj);
            } else {
              alert(response.data.code);
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.loading.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
